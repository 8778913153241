<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import { appContainer } from "../../../container";
import { AuthService } from "../../../services/authService";
import {
  AllRaterType,
  InvitedUserInterface,
  Role,
} from "../../../interface/UserInterface";
import { UserService } from "../../../services/userService";
import { ToastService } from "../../../services/toastService";
import { LoadingService } from "../../../services/loadingService";
import { PagingInterface } from "../../../interface/PaginationInterface";
import AddRaterModal from "../../admin/modal/AddUserModal.vue";
import UserListingCard from "../../admin/UserCardListing.vue";
import { CurrentInvitationStore } from "../../../stores/CurrentInvtationStore";
import router from "../../../routes";
import { StorageKeys, StorageService } from "../../../services/storage.service";
import { ActiveRoleStore } from "../../../stores/ActiveRoleStore";
import { CompanyService } from "../../../services/manageCompanyService";

const authService = appContainer.resolve(AuthService);
const userService = appContainer.resolve(UserService);
const toastService = appContainer.resolve(ToastService);
const loadingService = appContainer.resolve(LoadingService);
const storageService = appContainer.resolve(StorageService);
const companyService = appContainer.resolve(CompanyService);

const currentInvitationStore = CurrentInvitationStore();
const showAddModal = ref(false);
const paginatedData = ref<PagingInterface<InvitedUserInterface>>();
const invitedUsers = ref<InvitedUserInterface[]>([]);
const invitationStore = CurrentInvitationStore();
const isSelfRater = ref(false);
const activeRoleStore = ActiveRoleStore();
const isLoading = ref(true);

onBeforeMount(async () => {
  await fetchUsers();
  isSelfRater.value = !!paginatedData.value?.items.filter(
    (item) => item.rater_type === AllRaterType.Self,
  ).length;
  isLoading.value = false;
});

async function fetchUsers(
  pageConfig: { page?: number; offset?: number } = {
    page: 1,
    offset: 8,
  },
) {
  const loader = await loadingService.show();
  return userService
    .getInvitedUser(
      {
        invitationType: Role.Raters,
        invited_for: authService.user()?.id,
        all: true,
        ...(currentInvitationStore.currentInvitation?.company_id
          ? {
              companyId: currentInvitationStore.currentInvitation?.company_id,
            }
          : {}),
        year: currentInvitationStore.currentInvitation?.meta.year,
      },
      pageConfig,
    )
    .then((result) => {
      paginatedData.value = result;
      invitedUsers.value = result.items.filter(
        (items) => items.rater_type != AllRaterType.Self,
      );
    })
    .finally(() => loader.hide());
}

async function addUser() {
  showAddModal.value = false;
  fetchUsers();
}

async function setSelfRater() {
  const userData = {
    first_name: invitationStore.currentInvitation!.invited_user.first_name,
    last_name: invitationStore.currentInvitation!.invited_user.first_name,
    email: invitationStore.currentInvitation!.invited_user.email,
    invited_for: invitationStore.currentInvitation!.invited_user.id,
    invitation_type: Role.Raters,
    rater_type: AllRaterType.Self,
    admin_id: invitationStore.currentInvitation!.invited_for_user!.id,
    duration_days: null,
  };
  if (invitationStore.currentInvitation!.company_id) {
    companyService
      .inviteEmployee(userData, invitationStore.currentInvitation!.company_id)
      .then(() => {
        toastService.success("You have been added as an Self Rater");
        isSelfRater.value = true;
      });
  } else {
    userService.addUser(userData).then(() => {
      toastService.success("You have been added as an Self Rater");
      isSelfRater.value = true;
    });
  }
}

async function handleRateYourSelf() {
  const selfRater = await userService
    .getInvitedUser({
      invitationType: Role.Raters,
      invited_for: authService.user()?.id,
      all: true,
      ...(currentInvitationStore.currentInvitation?.company_id
        ? {
            companyId: currentInvitationStore.currentInvitation?.company_id,
          }
        : {}),
    })
    .then((result) => {
      return result.items.find((item) => item.rater_type === AllRaterType.Self);
    });
  await authService.fetchUser();
  const activeRole = authService
    .user()
    ?.roles.filter((role) => role.role_name === Role.Raters)[0];
  await storageService.set(StorageKeys.Current_Invitation, selfRater);

  await storageService.set(StorageKeys.Active_Role, activeRole);
  await invitationStore.getCurrentInvitation();
  await activeRoleStore.getActiveRole();
  router.replace("instructions");
}
</script>
<template>
  <template v-if="!isLoading">
    <div class="d-flex w-100 justify-content-between flex-wrap mb-4 gap-10">
      <h2 class="fw-medium fs-4 m-0">Manage Raters</h2>
      <div class="btn-wrapper d-flex align-items-center flex-wrap gap-4">
        <button
          class="btn btn-secondary btn-sm text-white"
          v-if="isSelfRater"
          @click="handleRateYourSelf"
        >
          Rate Yourself
        </button>
        <button
          class="btn btn-primary btn-sm text-white"
          @click="showAddModal = true"
          v-if="isSelfRater"
        >
          Add Rater
        </button>
        <button
          class="btn btn-secondary btn-sm text-white"
          @click="setSelfRater"
          v-else
        >
          Add Yourself as Rater
        </button>
      </div>
    </div>
    <template v-if="!isSelfRater">
      <p class="fw-bold">Let's get started!</p>
      <ol>
        <li class="mb-3">
          You will first need to set yourself up as the
          <strong>Self Rater</strong>. This will then allow you to proceed with
          entering your TL360° Raters. Click on the 'Add Yourself as Rater'
          button above.
        </li>
        <li class="mb-3">
          Once you have added yourself as a Rater, you will be able to add
          Raters for other categories, such as Supervisor, Peer, Direct Report,
          and Other. You will need to provide the following details to add them:

          <ol class="child">
            <li>Names (First and Last)</li>
            <li>Email address (please ensure accuracy)</li>
            <li>
              Rater designation: Supervisor, Peer, Direct Report, Other
              <i
                >(The 'Other' category is flexible and can include Friends,
                Family, Customers, Board Members, etc.)
              </i>
            </li>
          </ol>
        </li>
        <li class="mb-3">
          You are required to add at least 15-20 Raters (if possible).
        </li>
        <!-- <li>
        Send the Trusted Leader 360° invitation to your raters. Click “Send
        All”.
      </li> -->
      </ol>
    </template>
    <template v-else>
      <div class="all-raters" v-if="invitedUsers.length">
        <UserListingCard
          v-for="user in invitedUsers"
          :key="user.id"
          :user="user"
          :showDetail="false"
          :currentRole="Role.Participants"
          @invite="fetchUsers"
          @delete="fetchUsers"
          @edit="fetchUsers"
        />
      </div>
      <div
        class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
        v-else
      >
        No Record Found
      </div>
    </template>

    <AddRaterModal
      v-if="showAddModal"
      @close="showAddModal = false"
      @add="addUser"
      :type="Role.Raters"
      :for="authService.user()!.id"
    />
  </template>
</template>

<style scoped lang="scss">
.no-result {
  height: 50vh;
}
ol {
  max-width: 900px;
}
.child {
  list-style: lower-alpha;
}
</style>
