<script setup lang="ts">
import { onMounted, ref } from "vue";
import { appContainer } from "../../container";
import {
  AllRaterType,
  InvitedUserInterface,
  Role,
} from "../../interface/UserInterface";
import router from "../../routes";
import { UserService } from "../../services/userService";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import { LoadingService } from "../../services/loadingService";

const currentInvitationStore = CurrentInvitationStore();
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);

const participant = ref<InvitedUserInterface>();
const daysLeft = ref<number | null>(0);

onMounted(async () => {
  await getParticipantData();
});

async function getParticipantData() {
  const loader = await loadingService.show();
  await userService
    .getInvitedUser({
      ...(currentInvitationStore.currentInvitation?.company_id
        ? {
            companyId: currentInvitationStore.currentInvitation?.company_id,
          }
        : {}),
      invited_user:
        currentInvitationStore.currentInvitation?.invited_for_user?.id,
      invited_for: currentInvitationStore.currentInvitation?.meta.admin_id,
      invitationType: Role.Participants,
    })
    .then((result) => {
      participant.value = result.items[0];
      daysLeft.value = result.items[0].days_left;
    })
    .finally(() => loader.hide());
}

const formatDate = (dateString: Date | string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};
</script>
<template>
  <div class="page-wrapper h-100 position-relative">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-4"
    >
      <h2 class="fw-medium fs-4">Instructions</h2>
    </div>
    <div class="instructions">
      <p>
        Thank you for your participation in the Trusted Leader 360° Assessment.
        This tool is designed to provide objective feedback through numerical
        scoring on key aspects of leadership.
      </p>
      <p>
        Your thoughtful and honest responses will play a crucial role in this
        process. Please review the following details before moving towards
        sample questions:
      </p>
      <p>
        <strong>Rating for: </strong
        >{{
          currentInvitationStore.currentInvitation?.rater_type ===
          AllRaterType.Self
            ? "Self"
            : `${currentInvitationStore.currentInvitation?.invited_for_user?.first_name} ${currentInvitationStore.currentInvitation?.invited_for_user?.last_name}`
        }}
      </p>
      <p>
        <strong>Days left for Assessment completion: </strong>{{ daysLeft }}
      </p>
      <p class="m-0">Time Stamps:</p>
      <ul>
        <li>
          <strong>Assessment Started on:</strong>
          {{ participant ? formatDate(participant.sent_on) : "N/A" }}
        </li>
        <li>
          <strong>Assessment Completion Date:</strong>
          {{
            participant
              ? formatDate(participant.meta.feedback_completion_date)
              : "N/A"
          }}
        </li>
      </ul>
      <button
        class="btn btn-secondary text-white px-4 start-btn mt-5"
        @click="router.push('/instructions/sample-question')"
      >
        Review Samples
      </button>

      <!-- <p>
        Thank you for your participation in the Trusted Leader 360° Assessment,
        which
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}
        </strong>
        has requested as part of their leadership development. This tool is
        designed to provide objective feedback through numerical scoring on key
        aspects of leadership. Your thoughtful and honest responses will play a
        crucial role in
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}&#39;s
        </strong>
        development.
      </p>

      <p>
        Your scores will remain anonymous and will be combined with those of
        other raters, with the exception of the Supervisor, who is typically the
        only individual identified by that designation. Towards the end of the
        assessment, you will also have the opportunity to provide additional
        comments to support
        <strong>
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.first_name
          }}
          {{
            currentInvitationStore.currentInvitation?.invited_for_user
              ?.last_name
          }}&#39;s
        </strong>
        growth and offer general feedback.
      </p>
      <p class="mt-5">
        Click on the <strong>Next Button</strong> above to read the Sample
        Questions
      </p> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>
.brand-logo {
  height: 50px;
  img {
    height: 100%;
    width: 100%;
  }
}
.instructions {
  max-width: 800px;
}

.page-footer {
  bottom: 50px;
}
@media (max-width: 575px) {
  .start-btn {
    padding: 6px;
    font-size: 14px;
  }
}
</style>
