<script setup lang="ts">
import { onMounted, ref } from "vue";
import { InvitedUserInterface, Role } from "../../interface/UserInterface";
import { appContainer } from "../../container";
import { UserService } from "../../services/userService";
import { useRoute } from "vue-router";
import { LoadingService } from "../../services/loadingService";
import UserListingCard from "../manage-users/components/UserListingCard.vue";
import { CurrentInvitationStore } from "../../stores/CurrentInvtationStore";
import { ActiveRoleStore } from "../../stores/ActiveRoleStore";

const allParticipants = ref<InvitedUserInterface[]>();
const userService = appContainer.resolve(UserService);
const loadingService = appContainer.resolve(LoadingService);

const activeRoleStore = ActiveRoleStore();

const route = useRoute();
const adminUserId = ref();
const currentInvitationStore = CurrentInvitationStore();
const isLoading = ref(true);
const admin = ref<InvitedUserInterface>();

onMounted(async () => {
  const loader = await loadingService.show();

  if (
    currentInvitationStore.currentInvitation?.invitation_type === Role.Admin
  ) {
    adminUserId.value =
      currentInvitationStore.currentInvitation.invited_user.id;
  } else {
    await getCurrentAdmin();
  }
  await getAllParticipant().finally(() => {
    isLoading.value = false;
    loader.hide();
  });
});

async function getCurrentAdmin() {
  return userService
    .getCurrentInvitation(Number(route.params.participantId))
    .then((result) => {
      admin.value = result;
      adminUserId.value = result.invited_user.id;
    });
}

async function getAllParticipant() {
  const isAll = true;
  return userService
    .getInvitedUser({
      invitationType: Role.Participants,
      all: isAll,
      ...(admin.value?.company_id
        ? {
            companyId: admin.value?.company_id,
          }
        : {}),
      ...(currentInvitationStore.currentInvitation?.company_id
        ? {
            companyId: currentInvitationStore.currentInvitation?.company_id,
          }
        : {}),
      invited_for: adminUserId.value,
      year: admin.value?.meta.year,
    })
    .then((result) => {
      allParticipants.value = result.items.filter(
        (item) => item.days_left! <= 0 && item.item_count > 0,
      );
    });
}
</script>
<template>
  <template v-if="isLoading"></template>
  <template v-else>
    <div
      class="breadcrumbs mb-4"
      v-if="activeRoleStore.activeRole?.role_name === 'super_admin'"
    >
      <router-link to="/manage-reports" class="text-decoration-none"
        >Manage Reports</router-link
      >
      / Participants
    </div>
    <div class="breadcrumbs fs-5 mb-4" v-else>Participants</div>

    <div v-if="allParticipants?.length">
      <UserListingCard
        v-for="participant of allParticipants"
        :showDetail="false"
        :user="participant"
        :key="participant.id"
        :forReports="true"
      />
    </div>
    <div
      class="no-result d-flex align-items-center justify-content-center text-opacity-50 text-danger vh-50"
      v-else
    >
      No Record Found
    </div>
  </template>
</template>

<style scoped lang="scss">
.no-result {
  margin-top: 50px;
}
</style>
