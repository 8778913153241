import { NavigationGuardWithThis } from "vue-router";

import { CurrentInvitationStore } from "../stores/CurrentInvtationStore";
import { appContainer } from "../container";
import { UserService } from "../services/userService";
import { Role } from "../interface/UserInterface";
import { ActiveRoleStore } from "../stores/ActiveRoleStore";

export const FeedbackExpiredGaurd: NavigationGuardWithThis<
  undefined
> = async () => {
  const userService = appContainer.resolve(UserService);
  const currentInvitationStore = CurrentInvitationStore();
  const activeRoleStore = ActiveRoleStore();

  if (activeRoleStore.activeRole?.role_name === Role.Participants) {
    await currentInvitationStore.getCurrentInvitation();

    return (currentInvitationStore.currentInvitation?.days_left as number) > 0
      ? true
      : "/feedback-expired";
  } else if (
    currentInvitationStore.currentInvitation?.invitation_type === Role.Raters
  ) {
    await currentInvitationStore.getCurrentInvitation();
    const participant = await userService
      .getInvitedUser({
        invited_user:
          currentInvitationStore.currentInvitation?.invited_for_user?.id,
        invitationType: Role.Participants,
        ...(currentInvitationStore.currentInvitation?.company_id
          ? {
              companyId: currentInvitationStore.currentInvitation?.company_id,
            }
          : {}),
      })
      .then((result) => result.items[0]);

    const participantData = await userService.feedbackDurationlleft(
      participant.id,
    );
    return participantData.days_left === 0 ? "/feedback-expired" : true;
  } else {
    return true;
  }
};
